.landing_hero_wrapper {
  background-image: url('/images/hero-bg.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.landing_hero_content {
  backdrop-filter: grayscale(100%) brightness(50%);
}

/* Disable parallax on mobile */
@media (max-width: 768px) {
  .landing_hero_wrapper {
    background-attachment: scroll;
  }
}
